import Button from "common/components/Button";
import GatsbyImage from "common/components/GatsbyImage";
import Heading from "common/components/Heading";
import Input from "common/components/Input";
import Text from "common/components/Text";
import Container from "common/components/UI/Container";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Fade from "react-reveal/Fade";
import Section, {
  BannerContent,
  BannerContentWrapper,
  Figure,
  Subscribe,
} from "./banner.style";

const Banner = () => {
  const illustrations = useStaticQuery(graphql`
    query {
      dashboard: file(
        relativePath: { eq: "image/saasCreative/dashboard.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <Section id="home">
      <Container width="1400px">
        <BannerContentWrapper>
          <BannerContent>
            <Fade up>
              <Heading content="Say goodbye to procrastination." />
            </Fade>
            <Fade up delay={100}>
              <Text content="Disciplind is the easiest way to improve your productivity habits with a personalized timeboxing tool that learns from the way you work." />
            </Fade>
            <Fade up delay={200}>
              <Subscribe>
                <Input
                  inputType="email"
                  placeholder="Enter Email Address"
                  iconPosition="left"
                  aria-label="email"
                />
                <Button title="Join Beta" type="submit" />
              </Subscribe>
              {/* <Text
                style={{ fontSize: 14 }}
                content="Take care of your time, the world wants to steal it from you."
              /> */}
            </Fade>
          </BannerContent>
          <Fade up delay={200}>
            <Figure>
              <GatsbyImage
                src={
                  (illustrations.dashboard !== null) | undefined
                    ? illustrations.dashboard.childImageSharp.gatsbyImageData
                    : {}
                }
                objectFit="contain"
                alt="Banner"
              />
            </Figure>
          </Fade>
        </BannerContentWrapper>
      </Container>
    </Section>
  );
};

export default Banner;
